
import { initializeApp } from 'firebase/app';
import {getStorage} from 'firebase/storage';
import {getFirestore} from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBBmaNHuQMVEqRr2BheHikPZdsb07STjec",
  authDomain: "mtfp-e0bcd.firebaseapp.com",
  projectId: "mtfp-e0bcd",
  storageBucket: "mtfp-e0bcd.appspot.com",
  messagingSenderId: "998345650848",
  appId: "1:998345650848:web:551b455b53e5f658af8fc5",
  measurementId: "G-PXEZSTYQ0L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the storage service and Firestore
const storage = getStorage(app);
const firestore = getFirestore(app);

export {app, storage, firestore}
