import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore'
import { firestore } from './firebaseConfig';

const CarouselList = () => {
  const [carousels, setCarousels] = useState([]);
  const [selectedCarousel, setSelectedCarousel] = useState(null);

  useEffect(() => {
    const fetchCarousels = async () => {
        const db = getFirestore();
        const querySnapshot = await getDocs(collection(db, 'carouselSettings'));
        const carouselArray = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }))
        setCarousels(carouselArray);
    }

    fetchCarousels();
  }, []);

  const extractFilename = (url) => {
    // Decode URI components first to handle encoded characters
    const decodedUrl = decodeURIComponent(url);
  
    // Extract the last segment after the last '/'
    const filename = decodedUrl.split('/').pop();
  
    // Remove any query string if present
    return filename.split('?')[0];
  };

  const handleCarouselClick = (event, carousel) => {
    event.preventDefault();
    const updatedCarousel = {
      ...carousel,
      filenames: carousel.files.map(file => extractFilename(file))
    };
    setSelectedCarousel(updatedCarousel);
  }

  const isSelected = (carouselId) => selectedCarousel && carouselId === selectedCarousel.id;

  return (
    <div>
      <h1>Carousel List</h1>
      <ul style={{marginTop: 25, marginLeft: 25, border: "1px solid grey", paddingLeft: 10, maxWidth: "33%"}}>
        {carousels.map(carousel => (
          <li key={carousel.id} style={{marginBottom: 10,  textDecoration: isSelected(carousel.id) ? 'underline' : 'none'}}>
            <a href="#" onClick={(e) => handleCarouselClick(e, carousel)}>
              {carousel.id}
            </a>
          </li>
        ))}
      </ul>
      {selectedCarousel && (
        <div style={{marginTop: 25}}>
            <h3>Details for {selectedCarousel.id}</h3>
            <pre>{JSON.stringify(selectedCarousel, null, 2)}</pre>
        </div>
      )}
    </div>
  )

}

export default CarouselList;