/* eslint-disable react/react-in-jsx-scope */
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './App.css'
import logo from "./assets/logo/logomtfporange2.png";
import twitter from "./assets/icons/twitter.png";
import introVideo from "./assets/video/intro.mp4";
import emailjs from 'emailjs-com';
import Widget from './Widget';
import CarouselComponent from './CarouselComponent';
import CarouselList from './CarouselList';


function App () {
  const displayMobileLinks = () => {
    const x = document.getElementById("myLinks");
    x.style.display = x.style.display === "block" ? "none" : "block";
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_gpj4p8h', 'template_v9m97nc', e.target, '8DPwL-jfR4JnGPNat')
      .then((result) => {
          console.log('Email successfully sent!', result.text);
          alert("Courriel envoyé avec succès!");
          e.target.reset();
      }, (error) => {
          console.log('Email failed to send.', error.text);
          alert("Echec dans l'envoi du courriel...");
      });

      
  }
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            <nav className="h-96">
                <div className="hidden h-full text-center text-white bg-transparent md:inline">
                    <ul className="z-50 pt-5 m-auto list-none bg-transparent ">
                        <li>
                        <a href="#accueil">Accueil</a>
                        </li>
                        <li>
                          <a href="#quote">À propos</a>
                        </li>
                        <li>
                            <a href="#accueil">
                              <img src={logo} alt="Logo" />
                            </a>
                        </li>
                        <li>
                          <a href="#career">Carrière</a>
                        </li>
                        <li>
                          <a href="#contact">Contact</a>
                        </li>
                        <li> 
                            <a href="https://twitter.com/ProductionsMTFP" target="_blank"><img src={twitter} alt="Twitter" /></a>
                        </li>
                    </ul>
                    
                    <video autoPlay muted loop>
                        <source src={introVideo} type="video/mp4" />
                    </video>
                </div>
                <div className="h-full pt-5 pr-10 text-right text-white bg-transparent md:hidden">          
                  <a href="javascript:void(0);" className="text-2xl" onClick={displayMobileLinks}>
                      <i className="fa fa-bars"></i>
                  </a>
                  <div id="myLinks" className="hidden text-2xl">
                    <a href="#accueil" className="block pb-2">Accueil</a>
                    <a href="#quote" className="block pb-2">À propos</a>
                    <a href="#career" className="block pb-2">Carrière</a>
                    <a href="#contact" className="block pb-2">Contact</a>
                    <a href="https://twitter.com/ProductionsMTFP" target="_blank" className="block float-right pt-2"><img src={twitter} alt="Twitter" /></a>
                  </div>
                  <video autoPlay muted loop>
                      <source src={introVideo} type="video/mp4" />
                  </video>
                </div>  
            </nav>
            <div id="productions" className="pt-16">
                <div className="flex flex-col items-center justify-center h-full text-center text-white">
                  <h1 className="text-6xl">Productions MTFP</h1>
                  <span className="mt-10 text-4xl">
                    Maison moderne de productions média
                  </span>
                  <span className="mt-10 mb-5 text-3xl max-w-[60%] m-auto text-center" style={{color: "#CF8B16"}}>
                    En tant que nouvelle maison de production médiatique, nous sommes animés par une volonté d'innovation et de créativité dans l'industrie du Québec. 
                    Nous croyons fermement en notre potentiel à générer un impact significatif grâce à la passion de nos talents et la puissance de nos idées. 
                  </span>
                  <span className="mt-5 mb-20 text-3xl max-w-[60%] m-auto text-center" style={{color: "#CF8B16"}}>
                    Qu'il s'agisse de diffusion en direct, de création de vidéos captivantes ou du tournage d'un podcast; nous sommes ici pour vous aider à réaliser vos idées!
                    Nous cherchons à susciter l'intérêt et l'inspiration et nous vous invitons à nous rejoindre dans cette aventure passionnante.
                  </span>
                </div>
            </div>
            <div id="quote">
              <p className="py-10 text-5xl italic text-white">
                  "Pour être irremplaçable, il faut être différent."
              </p>
            </div>
            <div className="icons">
                <div>
                    <img src="./assets/icons/production-video.png" alt="Production" /><br/>
                    PRODUCTIONS
                </div>
                <div>
                    <img src="./assets/icons/podcast.png" alt="Podcast" /><br/>
                    PODCASTS
                </div>
                <div>
                    <img src="./assets/icons/diffusion.png" alt="Diffusion" /><br/>
                    WEB DIFFUSIONS
                </div>
                <div>
                    <img src="./assets/icons/evenement.png" alt="Evenement" /><br/>
                    ÉVÉNEMENTIEL
                </div>
            </div>
            <div id="career">
                <h1 className="text-6xl text-white">Travailler avec nous</h1>
                <h2>Nous sommes victimes de notre succès ; on a un gros problème : on en a plein les bras !</h2>
                <p>
                    Tu es une personne dynamique, polyvalente et débrouillarde? C’est ton occasion applique ! <br/>
                    Nous t’offrons la chance de faire partie d’une entreprise jeune et ambitieuse, avec une équipe qui est <br/>
                    toujours de la partie quand il s‘agit de déconnecter, de se réunir et d’avoir du plaisir! <br/>
                    De ton bord, tu nous offres ta passion et ta compétence.
                </p>
                <h2>Envoi ton cv et ta lettre de présentation à</h2>
                <h2 style={{paddingTop: "0px", paddingBottom: "18px"}}><a href="mailto:oswaldt@productionsmtfp.ca">oswaldt@productionsmtfp.ca</a></h2>
            </div>
            <div id="contact">
              <h1 className="text-6xl text-white">Nous contacter</h1>
              <h2>Pour toutes questions ou demandes d'informations</h2>
              <div className="m-auto mt-4 w-full md:w-[70%]">
                  <div className="inline-block w-full m-auto align-top md:w-1/2" >
                    <form onSubmit={sendEmail} className="m-auto text-center text-black md:text-start">
                        <input type="text" name="name" placeholder="Nom"/><br/>
                        <input name="email" type="text" placeholder="Courriel"/><br/>
                        <textarea name="message" placeholder="Message" rows={10}/><br/>
                        <button id="submit" style={{textAlign: "center"}}>Envoyer</button>
                      </form>
                  </div>
                  <div className="inline-block w-full mt-10 text-center align-top md:w-1/2 md:mt-5">
                      <p className="m-auto w-[80%]" style={{marginTop: "0", fontSize: "18px"}}>
                          Nous sommes toujours à la recherche de <strong>partenaires et commanditaires</strong>.
                      </p>
                      <p className="m-auto w-[80%]" style={{marginTop: "10px", fontSize: "18px"}}>
                          Pour nous faire parvenir des <strong>produits / échantillons</strong>, voici notre adresse de case postale.
                      </p>
                      <div className="m-auto w-[50%] mt-5 text-center text-white" style={{fontSize: "18px", border: "1px solid white", padding: "10px"}}>
                          <h3>Case postale</h3>
                          CP 1155 <br/>
                          St-Basile-le-Grand <br/> 
                          J3N 1M6 <br/>
                      </div>
                  </div>
              </div>
          </div>
          </> } 
        />
        <Route path="/widget" element={<Widget />} />
        <Route path="/carousel/:uuid" element={<CarouselComponent />} />
        <Route path="/carousels" element={<CarouselList />} />
      </Routes>
    </Router>
  )
}

export default App
