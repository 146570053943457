import React, { useState, useEffect } from 'react';

import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import { app, storage, firestore } from './firebaseConfig';

import './Widget.css';

const Widget = () => {

  const [files, setFiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [delay, setDelay] = useState(5000); // Initial delay set to 5 seconds
  const [pause, setPause] = useState(5); // Pause duration in seconds
  const [isPaused, setIsPaused] = useState(false);
  const [timer, setTimer] = useState(0); // Countdown timer

  useEffect(() => {
    const auth = getAuth(app);
  
    signInAnonymously(auth)
      .then(() => {
        console.log("Signed in anonymously");
      })
      .catch((error) => {
        console.error("Error signing in anonymously:", error);
      });
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is signed in anonymously");
        // User is signed in, you can handle user state here
      } else {
        console.log("User is not signed in");
        // User is signed out
      }
    });
  
    return () => unsubscribe(); // Clean up the subscription on unmount
  }, []);
  
 
  const handleFileDrop = (event) => {
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
    }
  };

  const handleDelayChange = (event) => {
    setDelay(event.target.value * 1000); // Convert seconds to milliseconds
  };

  const handlePauseChange = (event) => {
    setPause(parseInt(event.target.value, 10));
  };
  
  const saveCarousel = async (files, delay, pause) => {
    // Generate a unique ID
    const uniqueId = uuidv4();

    // Upload images and get their URLs
    const uploadPromises = files.map(file => {
        const storageRef = ref(storage, `images/${uniqueId}/${file.name}`);
        return uploadBytes(storageRef, file).then(() => getDownloadURL(storageRef));
    });
    console.log('images uploaded')

    try {
        const fileUrls = await Promise.all(uploadPromises);

        // Construct the state with URLs
        const savedState = {
            files: fileUrls,
            delay,
            pause
        };

        // Use the same unique ID for the Firestore document
        const docRef = doc(firestore, "carouselSettings", uniqueId);

        // Save the state to Firestore
        await setDoc(docRef, savedState);

        console.log(`Unique URL: /carousel/${uniqueId}`);
        console.log(`Saved State: ${JSON.stringify(savedState)}`);

        alert(`Carousel saved! Unique URL: /carousel/${uniqueId}`)
    } catch (error) {
        console.error("Error saving carousel:", error);
    }
  };

  useEffect(() => {
    if (isPaused) {
      if (timer > 0) {
        setTimeout(() => setTimer(timer - 1), 1000);
      } else {
        setIsPaused(false);
        setTimer(delay / 1000);
        setCurrentIndex(0);
      }
    } else {
      if (files.length > 0 && timer > 0) {
        setTimeout(() => setTimer(timer - 1), 1000);
      } else if (files.length > 0) {
        setCurrentIndex(prevIndex => {
          if (prevIndex + 1 >= files.length) {
            setIsPaused(true);
            setTimer(pause);
            return 0;
          }
          return prevIndex + 1;
        });
        setTimer(delay / 1000);
      }
    }
  }, [timer, files, delay, pause, isPaused]);
  
  function renderFile(file) {
    const url = URL.createObjectURL(file);
    if (!isPaused) {
      if (file.type.startsWith('image/')) {
        return <img src={url} alt={file.name} style={{ maxWidth: '100%' }} />;
      } else if (file.type.startsWith('video/') && file.type === 'video/webm') {
        return (
          <video controls style={{ maxWidth: '100%' }}>
            <source src={url} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        );
      }
    }
    return null;
  }
  
  
  
  return (
    <div>
      <input type="file" multiple onChange={handleFileDrop} />
      <div>
        Delay (seconds): <input type="number" min="1" max="30" value={delay / 1000} onChange={handleDelayChange} />
        <br />
        Pause between cycles (seconds): <input type="number" min="1" max="60" value={pause} onChange={handlePauseChange} />
      </div>
      <div>
        {files.length > 0 && renderFile(files[currentIndex])}
      </div>
      <div>
        {isPaused ? `Pause timer: ${timer} seconds` : `Next image in: ${timer} seconds`}
      </div>
      <button onClick={() => saveCarousel(files, delay, pause)} className="save-carousel-btn">Save Carousel</button>
    </div>
    
  );
};

export default Widget;
