import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { useParams } from 'react-router-dom';

const CarouselComponent = () => {
  const { uuid } = useParams();
  const [carouselData, setCarouselData] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const videoRef = useRef(null);

  const playVideoFromStart = () => {
    if (videoRef.current && isWebM(carouselData.files[currentImageIndex])) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };


  useEffect(() => {
    console.log("Fetching carousel data...");
    const fetchCarouselData = async () => {
      const docRef = doc(firestore, "carouselSettings", uuid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Carousel data found and set.");
        setCarouselData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchCarouselData();
  }, [uuid]);

  useEffect(() => {
    let intervalId;
  
    const startCarousel = () => {
      console.log("Starting carousel...");
      intervalId = setInterval(() => {
        setCurrentImageIndex(prevIndex => {
          const nextIndex = (prevIndex + 1) % carouselData.files.length;
          
          // Check if the next index is 0, which means we've reached the end
          if (nextIndex === 0) {
            console.log("Last item reached. Starting pause...");

            // Clear the interval and set isPaused to true to start the pause
            clearInterval(intervalId);
            setIsPaused(true);

            // Restart the carousel after the pause duration
            setTimeout(() => {
              setIsPaused(false);
              setCurrentImageIndex(0); // Reset to start from the first item
              playVideoFromStart();
            }, carouselData.pause * 1000);
          }

          return nextIndex;
        });
      }, carouselData.delay);
    };
  
    if (!carouselData || carouselData.files.length === 0) return;
  
    if (!isPaused) {
      startCarousel(); // Start or restart the carousel
      playVideoFromStart();
    }
  
    // Cleanup function
    return () => {
      clearInterval(intervalId);
    };
  }, [carouselData, isPaused]);

  const isWebM = (url) => {
    const webmPattern = /\.webm($|\?)/;
    return webmPattern.test(url);
  };

  const handleVideoEnd = () => {
    console.log('Video ended');
    setCurrentImageIndex(index => {
      const nextIndex = (index + 1) % carouselData.files.length;
      if (nextIndex === 0) {
        // If the last video in the carousel ends, reset the index and pause
        setIsPaused(true);
      }
      return nextIndex;
    });
  };
  
  
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.addEventListener('ended', handleVideoEnd);

      return () => {
        video.removeEventListener('ended', handleVideoEnd);
      }
    }
  }, [currentImageIndex]); 

  const renderMedia = (fileUrl, index) => {
    return isWebM(fileUrl) ? (
      <video
        key={fileUrl}
        src={fileUrl}
        autoPlay
        muted
        style={{ display: index === currentImageIndex && !isPaused ? 'block' : 'none' }}
        ref={videoRef}
      />
    ) : (
      <img
        key={fileUrl}
        src={fileUrl}
        alt={`Carousel Media ${index + 1}`}
        style={{ display: index === currentImageIndex && !isPaused ? 'block' : 'none' }}
      />
    );
  };

  if (!carouselData) {
    return <div>Loading carousel...</div>;
  }

  return (
    <div>
      {carouselData.files.map((fileUrl, index) => renderMedia(fileUrl, index))}
    </div>
  );
};

export default CarouselComponent;
